.breach-container {
  min-width: 100px;
  margin: 0 auto;
  padding: 20px 30px;
  color: var(--theme-primary-text) !important;
  background-color: var(--theme-background-secondary) !important;
}

.header {
  display: flex;
  align-items: center;
}

.breach-input:not(:last-of-type) {
  margin-bottom: 35px;
}

.breach-input:last-of-type {
  margin-bottom: 15px;
}

.breach-input-text-field {
  width: 100%;
}

.required-text {
  color: red;
  font-weight: 600;
  vertical-align: top;
}

#back-button {
  margin-left: -20px;
}

#search-breach-button {
  background-color: #e16637;
  color: var(--theme-primary-text);
  margin: 10px auto;
  width: 250px;
}

#breach-error {
  background-color: rgba(255, 0, 0, 0.2);
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  max-width: 400px;
  margin: 25px auto auto auto;
}

#submit-loading, #search-breach-button {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.list-container {
  padding-left: 1vw;
}

.list-item-primary {
  color: var(--theme-text);
  font-weight: 600;
  font-size: 1.2rem;
}

.list-item-secondary {
  color: var(--theme-primary-text);
}

.pay-breach-button-container {
  margin: 0 auto;
  width: 60%;
}

.pay-breach-button {
  background-color: #e16637 !important;
  color: var(--theme-primary-text) !important;
  width: 100%;
}

.pay-breach-error {
  color: var(--theme-primary-text);
  background-color: rgba(255, 0, 0, 0.2);
  padding: 5px 10px;
  margin-top: 10px;
}

.error-message-success {
  color: var(--theme-primary-text);
  background-color: rgba(0, 255, 0, 0.2);
  padding: 5px 10px;
  margin-top: 20px;
}

.error-message {
  color: var(--theme-primary-text);
  background-color: rgba(255, 0, 0, 0.2);
  padding: 5px 10px;
  margin-top: 20px;
}

/** Overrides **/
.MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-background-trim) !important;
}

.breach-input-text-field > .MuiInputBase-root {
  color: var(--theme-primary-text);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: skyblue !important;
}