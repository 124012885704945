* {
    box-sizing: border-box;
}

/* global variables */
:root {
  --theme-primary-text: white;
  --theme-text: #c1a661;
  --theme-background: #505050;
  --theme-background-secondary: #404040;
  --theme-background-trim: gray;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-background);
  color: var(--theme-primary-text);
}

#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  height: 396px;
  background-image: url('./assets/NCS-Banner-bg.jpg');
  background-size: cover;
  background-position: right;
}

#content {
  height: 100%;
  min-width: 200px;
  width: 100%;
  max-width: 900px;
  padding: 10px;
  margin-bottom: 50px;
  flex-grow: 1;
  align-self: center;
}

footer {
  border-top: 4px solid var(--theme-text);
  background-color: #505050;
  padding: 5px 10px;

  box-shadow: 10px 10px 8px 7px #383838;
}

#footer-container {
  min-width: 200px;
  width: 100%;
  max-width: 900px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

#footer-col-1 {
  height: 100%;
}

.footer-header {
  font-weight: 700;
  font-size: 1.1rem;
}

.footer-col-items {
  display: flex;
  align-items: flex-end;
  list-style-type: none;
  text-decoration: underline;
  margin: 10px 0px;
}

.payment-success-message {
  margin: 30px 0px;
}

.go-back-link {
  color: skyblue !important;
}

a:visited { text-decoration: none; color: var(--theme-primary-text); }
a:hover { text-decoration: none; color: var(--theme-primary-text) }
a:focus { text-decoration: none; color: var(--theme-primary-text) }
a:hover, a:active { text-decoration: none; color: var(--theme-primary-text) }